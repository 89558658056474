import { createSlice } from '@reduxjs/toolkit';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: [],
  reducers: {
    addMessage: (state, action) => {
      const {message, sender, direction} = action.payload;
      state.push({message, sender, direction});
    },
  },
});

export const { addMessage } = messagesSlice.actions;

export default messagesSlice.reducer;
