import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../ducks/slices/userSlice";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "./TopNav.scss";
import ClockIcon from "./../../assets/clock.svg";

function formatTime(milliseconds) {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);

  if (minutes > 0) {
    return `${minutes} m left`;
  } else {
    return `${seconds} seconds left`;
  }
}

const TopNav = ({
  setDisplayModal,
  setAuthModalState,
  showAssessmentButton,
  timeLeft,
}) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    checkPrivacy();
  }, []);

  const checkPrivacy = async () => {
    try {
      const res = await axios.get(`/auth/getUser`);
      dispatch(
        updateUser({
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
        })
      );
      setLoggedIn(true);
    } catch {
      navigate("/");
      setLoggedIn(false);
    }
  };

  const logout = async () => {
    try {
      await axios.post(`/auth/logout`);
      dispatch(updateUser({}));
      setLoggedIn(false);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const renderMenuItems = () => {
    const items = [
      {
        label: "Interview",
        onClick: () => navigate("/interview"),
      },
      // {
      //   label: "See Assessment",
      //   onClick: () => navigate("/assessment"),
      // },
      {
        label: "Logout",
        onClick: logout,
      },
    ];

    return items.map((item) => (
      <MenuItem
        key={item.label}
        onClick={item.onClick}
        style={{
          backgroundColor: "#2D3748", // Matches the dark gray color in the image
          color: "white",
          padding: "15px",
          borderRadius: "6px",
          marginBottom: "8px",
        }}
        _hover={{
          bg: "#4A5568", // Lighter gray when hovered
        }}
      >
        {item.label}
      </MenuItem>
    ));
  };

  if (!loggedIn) {
    return (
      <div className="topnav">
        <div className="topnav-left">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <p id="company-name">Bayesient AI</p>
          </button>
        </div>
        <div className="topnav-right">
          <button
            id="green-button"
            onClick={() => {
              setAuthModalState("login");
              setDisplayModal(true);
            }}
          >
            Sign In
          </button>
          <button
            id="purple-button"
            onClick={() => {
              setAuthModalState("register");
              setDisplayModal(true);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="topnav">
        <div className="topnav-left">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <p id="company-name">Bayesient AI</p>
          </button>
        </div>
        <div className="topnav-right">
          {typeof timeLeft === "number" && timeLeft > 0 && (
            <div className="timer">
              <img src={ClockIcon} alt="Timer Icon" id="timer-icon" />
              <p id="timer-text">{formatTime(timeLeft)}</p>
            </div>
          )}
          {showAssessmentButton && (
            <button
              id="green-button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                padding: "0px 15px",
                fontSize: "16px",
                fontWeight: "600",
                marginRight: "10px",
              }}
              onClick={() => {
                navigate("/assessment");
              }}
            >
              See assessment
            </button>
          )}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              style={{
                backgroundColor: "#2D3748", // Matches the dark gray color in the image
                color: "white",
                padding: "15px",
                borderRadius: "6px",
              }}
            >
              {user.firstName} {user.lastName}
            </MenuButton>
            <MenuList
              style={{
                backgroundColor: "#2D3748", // Same background color as the MenuButton
                borderColor: "#1A202C", // Darker gray for the border
                borderRadius: "10px",
                width: "200px",
              }}
            >
              {renderMenuItems()}
            </MenuList>
          </Menu>
        </div>
      </div>
    );
  }
};

export default TopNav;
