import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    updateUser: (state, action) => {
      const { username, email, firstName, lastName } = action.payload;
      return { username, email, firstName, lastName };
    },
  },
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
