import "./CandidateCoderpad.scss";
import { Editor } from "@monaco-editor/react";
import { useDispatch, useSelector } from "react-redux";
import { setCandidateEditorValue } from "../../ducks/slices/coderPadSlice";

const CandidateCoderpad = ({ candidateId, socket, programmingLanguage }) => {
  const candidateEditorValue = useSelector(
    (state) => state.coderPad.candidateEditorValue
  );

  const dispatch = useDispatch();

  const handleEditorChange = (value, event) => {
    dispatch(setCandidateEditorValue(value));
  };

  return (
    <div className="coderpad">
      <Editor
        height="100%"
        width="100%"
        theme="vs-dark"
        defaultLanguage={programmingLanguage.toLowerCase()}
        onChange={handleEditorChange}
        value={candidateEditorValue}
        options={{
          fontSize: 16,
          padding: 20,
        }}
        // onMount={handleEditorDidMount}
      />
    </div>
  );
};

export default CandidateCoderpad;
