import React, { useState, useEffect } from "react";
import { Mic } from "lucide-react";
import {
  initializeTranscriptionService,
  startStream,
  stopStream,
} from "../../services/TranscriptionService";
import "./SpeechCircle.scss";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../../ducks/slices/messagesSlice";
import AudioService from "../../services/AudioService";

const audioService = new AudioService();
initializeTranscriptionService();

const SpeechCircle = ({ socket, candidateId }) => {
  const [isActive, setIsActive] = useState(false);
  const [transcript, setTranscript] = useState("");
  
  const candidateEditorValue = useSelector(
    (state) => state.coderPad.candidateEditorValue
  );
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("message audio sent - backend", ({ audioBuffer }) => {
      audioService.handleAudioBuffer(audioBuffer);
    });

    socket.on("enable audio input - backend", () => {
      if (!audioService.isPlaying) {
        startRecording();
      } else {
        audioService.setOnCompleteCallback(startRecording);
      }
    });

    socket.on("disable audio input - backend", () => {
      stopRecording();
    });

    return () => {
      audioService.interrupt(); // Interrupt any playing audio
      socket.off("message audio sent - backend");
      socket.off("enable audio input - backend");
      socket.off("disable audio input - backend");
      stopRecording();
      
    };
  }, []);

  const handleSendMessage = (messageText) => {
    if (typeof messageText !== "string" || messageText.length === 0) {
      return;
    }
    stopRecording();
    const message = {
      message: messageText,
      sender: "User",
      direction: "outgoing",
    };
    dispatch(addMessage(message));
    socket.emit("message sent - frontend", {
      candidateId: candidateId,
      message,
      candidateCoderPad: candidateEditorValue,
    });
    setTranscript("");
  };

  const startRecording = () => {
    audioService.interrupt(); // Interrupt any playing audio
    startStream((text, isFinal) => {
      setTranscript(text);
      if (isFinal) {
        handleSendMessage(text);
      }
    });
    setIsActive(true);
  };

  const stopRecording = () => {
    stopStream();
    setIsActive(false);
  };

  const toggleRecording = () => {
    if (isActive) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div className="speech-circle">
      <div className="transcript-container">
        <div className="transcript">
          {transcript === ""
            ? "Transcribed audio will show here before auto-send"
            : transcript}
        </div>
      </div>
      <button
        onClick={toggleRecording}
        className={`circle-button ${isActive ? "active" : ""}`}
      >
        <Mic />
      </button>
    </div>
  );
};

export default SpeechCircle;
