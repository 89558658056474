// Third-party imports
import { Routes, Route } from "react-router-dom";

// Global styles
import "./reset.css";
import "./App.scss";

// Page imports
import Interview from "./Pages/Interview/Interview";
import Assessment from "./Pages/Assessment/Assessment";
import Home from "./Pages/Home/Home";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/assessment" element={<Assessment />} />
      </Routes>
    </div>
  );
}

export default App;
