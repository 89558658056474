import "./Home.scss";
import { useState } from "react";
import AuthModal from "../../Components/AuthModal/AuthModal";
import TopNav from "../../Components/TopNav/TopNav";

const Home = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [authModalState, setAuthModalState] = useState("login");
  return (
    <div className="home-page">
      {displayModal && (
        <AuthModal
          setDisplayModal={setDisplayModal}
          authModalState={authModalState}
          setAuthModalState={setAuthModalState}
        />
      )}
      <TopNav
        setDisplayModal={setDisplayModal}
        setAuthModalState={setAuthModalState}
      />
      <div className="body">
        <p id="product-name">Bayesient AI</p>
        <div className="sku-box-container">
          <div className="sku-box">
            <p id="product-description">
              <span id="b2c-question">Wanna ace your interviews? </span>
              Empower your early career in software engineering with our AI
              Interviewer. Practice, improve, and ace your technical interviews
              with curated feedback.
            </p>
            <button
              id="start-interview-button"
              onClick={() => setDisplayModal(true)}
            >
              Start Interviewing
            </button>
          </div>
          <div className="sku-box">
            <p id="product-description">
              <span id="b2b-question">Looking to hire SWEs? </span>
              Use our AI Interviewer to better understand the technical
              capabilities of your candidates by putting them in a realistic
              interview environment at a fraction of the cost.
            </p>
            <a href="https://forms.gle/ucU94ecoj7uJhuGY8" target="_blank">
              <button id="contact-us-button">Contact Us</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
