let recognition = null;
let onTranscriptCallback = null;

export const initializeTranscriptionService = () => {
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  if (SpeechRecognition) {
    recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = (event) => {
      let interimTranscript = '';
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + ' ';
        } else {
          interimTranscript += transcript;
        }
      }

      const fullTranscript = finalTranscript + interimTranscript;
      if (onTranscriptCallback) {
        onTranscriptCallback(fullTranscript, event.results[event.results.length - 1].isFinal);
      }
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error', event.error);
    };
  } else {
    console.error('Speech recognition not supported in this browser');
  }
};

export const startStream = (callback) => {
  if (!recognition) {
    console.error('Speech recognition not initialized');
    return;
  }

  onTranscriptCallback = callback;
  recognition.start();
};

export const writeToStream = (chunk) => {
  // This function is not needed for Web Speech API
  // It's kept for compatibility with the previous implementation
};

export const stopStream = () => {
  if (recognition) {
    recognition.stop();
  }
};