export default class AudioService {
  constructor(onCompleteCallback = null) {
    this.audioQueue = [];
    this.isPlaying = false;
    this.currentAudio = null; // Store the current audio element
    this.onCompleteCallback = onCompleteCallback; // Store the callback function
  }

  handleAudioBuffer(audioBuffer, onCompleteCallback = null) {
    console.log("AUDIO");
    const blob = new Blob([audioBuffer], { type: "audio/mp3" });
    const url = URL.createObjectURL(blob);
    this.audioQueue.push(url);
    if (onCompleteCallback) {
      this.onCompleteCallback = onCompleteCallback; // Update callback if provided
    }
    this.playNextAudio();
  }

  playNextAudio() {
    if (this.isPlaying || this.audioQueue.length === 0) return;

    this.isPlaying = true;
    this.currentAudio = new Audio(this.audioQueue.shift());
    this.currentAudio.onended = () => {
      this.isPlaying = false;
      URL.revokeObjectURL(this.currentAudio.src);
      if (this.audioQueue.length > 0) {
        this.playNextAudio();
      } else if (this.onCompleteCallback) {
        console.log("All audio played"); // Log completion message if provided by the caller
        this.onCompleteCallback(); // Execute callback when all audio is played
      }
    };
    this.currentAudio.play().catch(console.error);
  }

  interrupt() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.isPlaying = false;
      this.currentAudio = null;
      this.onCompleteCallback = null;
    }
    this.audioQueue = []; // Clear the queue
  }

  setOnCompleteCallback(callback) {
    this.onCompleteCallback = callback;
  }
}
