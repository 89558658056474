import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./AIChat.scss";
import { addMessage } from "../../ducks/slices/messagesSlice";
import sendMessageIcon from "./../../assets/up-arrow.png";

const AIChat = ({ socket, candidateId }) => {
  const [messageInput, setMessageInput] = useState("");
  const messages = useSelector((state) => state.messages);
  const [allowCandidateInput, setAllowCandidateInput] = useState(false);
  const candidateEditorValue = useSelector(
    (state) => state.coderPad.candidateEditorValue
  );

  const dispatch = useDispatch();

  const messageListRef = useRef(null); // Create a ref for the message list

  useEffect(() => {
    socket.on("message sent - backend", ({ chatMessage }) => {
      dispatch(addMessage(chatMessage));
    });

    socket.on("enable input - backend", () => {
      setAllowCandidateInput(true);
    });

    socket.on("disable input - backend", () => {
      setAllowCandidateInput(false);
    });

    socket.on("error occurred - backend", (chatMessage) => {
      dispatch(addMessage(chatMessage));
    });

    return () => {
      socket.off("message sent - backend");
      socket.off("enable input - backend");
      socket.off("disable input - backend");
      socket.off("error occurred - backend");
    };
  }, []);

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage(messageInput);
    }
  };

  const handleSendMessage = (messageText) => {
    if (typeof messageText !== "string" || messageText.length === 0) {
      return;
    }
    const message = {
      message: messageText,
      sender: "Candidate",
      direction: "outgoing",
    };
    dispatch(addMessage(message));
    socket.emit("message sent - frontend", {
      candidateId: candidateId,
      message,
      candidateCoderPad: candidateEditorValue,
    });
    setMessageInput("");
  };

  const messagesMap = (messages) => {
    return messages.map((message, i) => {
      return (
        <div key={i} className="message-block" id={message.direction}>
          <div className="message">{message.message}</div>
        </div>
      );
    });
  };

  return (
    <div className="aichat">
      <div className="message-list" ref={messageListRef}>
        {messagesMap(messages)}
      </div>
      <div className="message-input-block">
        <input
          className="message-input"
          disabled={allowCandidateInput ? "" : "disabled"}
          value={messageInput}
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          onChange={(event) => {
            if (event.key !== "Enter") {
              setMessageInput(event.target.value);
            }
          }}
        />
        <button
          id="send-message-button"
          onClick={() => {
            handleSendMessage(messageInput);
          }}
        >
          <img src={sendMessageIcon} alt="Send Message" />
        </button>
      </div>
    </div>
  );
};

export default AIChat;
