import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import axios from "axios";
import {
  ChakraProvider,
  extendTheme,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "./Interview.scss";

// Component imports
import AIChat from "./../../Components/AIChat/AIChat";
import CandidateCoderpad from "./../../Components/CandidateCoderpad/CandidateCoderpad";
import SpeechCircle from "./../../Components/Speech/SpeechCircle";
import { updateAssessment } from "../../ducks/slices/assessmentSlice";
import { updateUser } from "../../ducks/slices/userSlice";
import TopNav from "../../Components/TopNav/TopNav";

// Map of programming languages
const languages = [
  { name: "JavaScript" },
  { name: "Python" },
  { name: "Java" },
  { name: "C#" },
  { name: "C++" },
  { name: "Ruby" },
  { name: "Go" },
  { name: "PHP" },
];

const difficultyOptions = [
  { name: "Easy" },
  { name: "Medium" },
  { name: "Hard" },
];

const socket = io();
const candidateId = uuidv4().toString();

const Interview = () => {
  const [programmingLanguage, setProgrammingLanguage] = useState("Python");
  const [timeLeft, setTimeLeft] = useState(0);
  const [showAssessmentButton, setShowAssessmentButton] = useState(false);
  const [questionDifficulty, setQuestionDifficulty] = useState("Easy");
  const [interviewState, setInterviewState] = useState("not-started");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkPrivacy();
    // startInterview();
    socket.on("assessment ready - backend", (assessment) => {
      dispatch(updateAssessment(assessment));
      setShowAssessmentButton(true);
      console.log("assessment received", { assessment });
    });

    socket.on("time left - backend", ({ timeLeft }) => {
      setTimeLeft(timeLeft);
    });

    socket.on("time's up - backend", () => {
      alert("Looks like you ran out of time");
    });

    return () => {
      socket.off("assessment ready - backend");
      socket.off("time left - backend");
      socket.off("time's up - backend");
    };
  }, []);

  const startInterview = () => {
    socket.emit("start interview - frontend", {
      candidateId,
      timeLimit: 900000,
      questionDifficulty,
    });
    setInterviewState("in-progress");
  };

  const checkPrivacy = async () => {
    try {
      const res = await axios.get(`/auth/getUser`);
      dispatch(
        updateUser({
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
        })
      );
    } catch {
      alert("Access denied! Please login first!");
      navigate("/");
    }
  };

  const theme = extendTheme({
    config: {
      initialColorMode: "dark",
      useSystemColorMode: false,
    },
  });

  const menuItems = languages.map((lang) => (
    <MenuItem
      key={lang.name}
      _hover={{ bg: "gray.600" }}
      onClick={() => setProgrammingLanguage(lang.name)}
    >
      {lang.name}
    </MenuItem>
  ));

  const difficultyMenuItems = difficultyOptions.map((option) => (
    <MenuItem
      key={option.name}
      _hover={{ bg: "gray.600" }}
      onClick={() => setQuestionDifficulty(option.name)}
    >
      {option.name}
    </MenuItem>
  ));

  const pageManager = () => {
    switch (interviewState) {
      case "not-started":
        return (
          <>
            <TopNav />
            <div className="interview-questions-page">
              <div id="info-box">
                <h2>Disclaimer</h2>
                <p>
                  Our AI interviewer is currently in beta—so, it's like
                  preparing for a big interview with a brand-new suit that's
                  still at the tailor's. We’re ironing out the wrinkles, and
                  your feedback is the final fitting! Help us out, and we
                  promise the end result will be as sharp as your resume.
                </p>
                <p>
                  When you're ready, feel free to dive in and choose your
                  difficulty level—no pressure, just your career on the line!
                  You can keep the mic on throughout for the full "real
                  interview" vibe, or go stealth mode and only turn it on when
                  you need to chat. Or hey, if you're feeling old school, type
                  your responses—just know the AI interviewer might start
                  wondering why you’re so quiet. We recommend keeping the mic
                  on, though—let's keep things as close to the real deal as
                  possible, minus the sweaty palms!
                </p>
              </div>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  bg="gray.700"
                  color="white"
                  _hover={{ bg: "gray.600" }}
                >
                  {questionDifficulty}
                </MenuButton>
                <MenuList bg="gray.800" borderColor="gray.700">
                  {difficultyMenuItems}
                </MenuList>
              </Menu>
              <button id="green-button" onClick={startInterview}>Start Interview</button>
            </div>
          </>
        );
      case "in-progress":
        return (
          <>
            <TopNav
              showAssessmentButton={showAssessmentButton}
              timeLeft={timeLeft}
            />
            <div className="interview-page">
              <div className="coderpad-container">
                <div className="coderpad-options">
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      bg="gray.700"
                      color="white"
                      _hover={{ bg: "gray.600" }}
                    >
                      {programmingLanguage}
                    </MenuButton>
                    <MenuList bg="gray.800" borderColor="gray.700">
                      {menuItems}
                    </MenuList>
                  </Menu>
                </div>
                <CandidateCoderpad
                  socket={socket}
                  candidateId={candidateId}
                  programmingLanguage={programmingLanguage}
                />
              </div>
              <div className="communication-container">
                <div className="ai-chat-container">
                  <AIChat socket={socket} candidateId={candidateId} />
                  <SpeechCircle socket={socket} candidateId={candidateId} />
                </div>
              </div>
            </div>
          </>
        );
      case "finished":
        return <h2>Interview finished</h2>;
      default:
        return <h2>Error occurred</h2>;
    }
  };

  return <ChakraProvider theme={theme}>{pageManager()}</ChakraProvider>;
};

export default Interview;
